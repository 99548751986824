@import "~antd/dist/antd.less";   // Import Ant Design styles by less entry

_:-ms-fullscreen, :root .ant-layout {
  height: 100vh;
}

.ant-layout {
  min-height: 100vh;
  overflow-x: hidden;
}

@primary-color: rgba(66,126,255,1);@error-color: rgba(255,22,117,1);@text-color: rgba(36,50,82,1);@text-color-inverse: rgba(255,255,255,1.0);@body-background: rgba(243,245,250,1);@layout-body-background: rgba(243,245,250,1);@background-color-base: rgba(173,186,208,1);@background-color-light: rgba(247,249,252,1);@border-color-base: rgba(173,186,208,1);@skeleton-color: rgba(243,245,250,1);@disabled-bg: rgba(243,245,250,1);@border-radius-base: 3px;@btn-border-radius-base: 3px;@grid-columns: 24;@btn-font-weight: 500;@screen-xs: 0480px;@screen-sm: 0576px;@screen-md: 0768px;@screen-lg: 0992px;@screen-xl: 1200px;@screen-xxl: 1500px;@btn-shadow: 0;@label-font-weight: 600;@skeleton-border-radius: 99px;